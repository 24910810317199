import React from 'react';
import {IProduct} from '../../../../types/galleryTypes';
import a11y from '@wix/wixstores-client-core/dist/es/src/assets/styles/_accessibility.scss';
import s from './ProductPrice.scss';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../../globalPropsContext';
import {BasePrice} from '@wix/wixstores-client-common-components/dist/es/src/BasePrice/BasePrice';
import {unitsTranslations} from './unitsTranslations';

export enum DataHook {
  SrPriceBeforeDiscount = 'sr-product-item-price-before-discount',
  SrPriceToPay = 'sr-product-item-price-to-pay',
  SrPriceRange = 'st-price-range',
  OutOfStock = 'product-item-out-of-stock',
  PriceBeforeDiscount = 'product-item-price-before-discount',
  PriceToPay = 'product-item-price-to-pay',
  PriceRange = 'price-range-from',
  BasePriceComponent = 'base-price-component',
}

interface TextMapProps {
  productPriceBeforeDiscountSR: string;
  productOutOfStockText: string;
  productPriceAfterDiscountSR: string;
  productPriceWhenThereIsNoDiscountSR: string;
}

export interface IProductPriceProps {
  product: IProduct;
  allowFreeProducts: boolean;
  textsMap: TextMapProps;
  isAddtoCartButtonExists: boolean;
  fromPrice?: string;
  isBasePriceEnabled?: boolean;
}

const getPriceBeforeDiscount = ({textsMap, product}) => (
  <>
    <span className={a11y.srOnly} data-hook={DataHook.SrPriceBeforeDiscount}>
      {textsMap.productPriceBeforeDiscountSR}
    </span>
    <span data-hook={DataHook.PriceBeforeDiscount} className={s.priceBeforeDiscount}>
      {product.formattedPrice}
    </span>
  </>
);

@withGlobals
@withTranslations()
class PriceRange extends React.Component<
  {formattedFromPrice: string; textsMap: TextMapProps} & IProvidedTranslationProps
> {
  public render() {
    const {formattedFromPrice, textsMap, t} = this.props;
    return (
      <>
        <span className={a11y.srOnly} data-hook={DataHook.SrPriceRange}>
          {textsMap.productPriceWhenThereIsNoDiscountSR}
        </span>
        <span data-hook={DataHook.PriceRange} className={s.priceFrom}>
          {t('priceRangeText', {formattedAmount: formattedFromPrice})}
        </span>
      </>
    );
  }
}

const RegularPrice = ({hasDiscount, product, textsMap}) => {
  return (
    <>
      {hasDiscount && getPriceBeforeDiscount({textsMap, product})}
      {
        <>
          <span className={a11y.srOnly} data-hook={DataHook.SrPriceToPay}>
            {hasDiscount ? textsMap.productPriceAfterDiscountSR : textsMap.productPriceWhenThereIsNoDiscountSR}
          </span>
          <span data-hook={DataHook.PriceToPay} className={s.priceToPay}>
            {hasDiscount ? product.formattedComparePrice : product.formattedPrice}
          </span>
        </>
      }
    </>
  );
};

@withGlobals
@withTranslations()
export class ProductPrice extends React.Component<IProductPriceProps & IProvidedTranslationProps, any> {
  public renderBasePrice(product) {
    const {
      formattedPricePerUnit,
      pricePerUnitData: {baseQuantity, baseMeasurementUnit},
    } = product;

    const noun = baseQuantity === 1 ? 'singular' : 'plural';
    const unitTranslation = this.props.t(unitsTranslations[baseMeasurementUnit].abbr);
    const screenReaderText = this.props.t('pricePerUnitSR', {
      basePrice: formattedPricePerUnit,
      units: `${baseQuantity} ${this.props.t(unitsTranslations[baseMeasurementUnit][noun])}`,
    });

    return (
      <BasePrice
        data-hook={DataHook.BasePriceComponent}
        className={s.basePrice}
        formattedPricePerUnit={formattedPricePerUnit}
        baseQuantity={baseQuantity}
        unitTranslation={unitTranslation}
        screenReaderText={screenReaderText}
      />
    );
  }

  public render() {
    const {product, allowFreeProducts, isBasePriceEnabled, isAddtoCartButtonExists, textsMap, fromPrice} = this.props;
    const isOutOfStock = !product.isInStock;
    const shouldRenderPrices = product.price !== 0 || allowFreeProducts;
    const hasDiscount = product.discount?.value > 0;
    const noRenderWhenDiscountedToZero = !(!allowFreeProducts && product.comparePrice === 0 && hasDiscount);
    const shouldRenderBasePrice = isBasePriceEnabled && product.formattedPricePerUnit;

    if (isOutOfStock && !isAddtoCartButtonExists) {
      return (
        <span data-hook={DataHook.OutOfStock} className={s.outOfStock}>
          {textsMap.productOutOfStockText}
        </span>
      );
    }

    if (!shouldRenderPrices || !noRenderWhenDiscountedToZero) {
      return null;
    }

    return (
      <div className={s.prices}>
        {fromPrice ? (
          <PriceRange formattedFromPrice={fromPrice} textsMap={textsMap} />
        ) : (
          <RegularPrice hasDiscount={hasDiscount} product={product} textsMap={textsMap} />
        )}
        {shouldRenderBasePrice && this.renderBasePrice(product)}
      </div>
    );
  }
}
